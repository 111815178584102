<template>
  <v-card v-if="!isLoadingTable">
    <DataTablePagination
      :page="page"
      :search="search"
      :total-items="totalItems"
      :total-pages.sync="totalPages"
      :headers="headers"
      :items="companies"
      title="Perusahaan"
      subtitle="Halaman untuk menambahkan data perusahaan"
      add-btn="Tambah Data"
      @add-item="showFormAdd"
      @edit-item="edit"
      @delete-item="confirmDestroy"
    >
    </DataTablePagination>
    <v-dialog
      v-model="addFormDialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="headline">
          Add Company
        </v-card-title>
        <v-card-text>
          <div>
            <v-file-input
              v-model="company.logo"
              :prependicon="icons.mdiImageMultipleOutline"
              label="Company Logo"
              dense
              outlined
            ></v-file-input>
          </div>
          <div>
            <v-text-field
              v-model="company.name"
              label="Company Name"
              dense
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="company.email"
              label="Email"
              dense
              type="email"
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="company.phone"
              label="Number Phone"
              dense
              type="number"
              outlined
            ></v-text-field>
          </div>
          <div>
            <v-textarea
              v-model="company.address"
              outlined
              label="Address"
            >
            </v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            large
            @click="addFormDialog = false"
          >
            Batal
          </v-btn>
          <v-btn
            :disabled="isLoadingButton"
            :loading="isLoadingButton"
            color="primary"
            large
            @click="add()"
          >
            Tambah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editFormDialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Company
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>
              <v-text-field
                v-model="company.logo"
                label="Logo"
                dense
                type="text"
                outlined
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model="company.name"
                label="Company Name"
                dense
                outlined
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model="company.email"
                label="Email"
                dense
                type="email"
                outlined
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model="company.phone"
                label="Number Phone"
                dense
                type="string"
                outlined
              ></v-text-field>
            </div>
            <div>
              <v-textarea
                v-model="company.address"
                outlined
                label="Address"
              >
              </v-textarea>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            large
            @click="editFormDialog = false"
          >
            Batal
          </v-btn>
          <v-btn

            :disabled="isLoadingButton"
            :loading="isLoadingButton"

            color="primary"
            large
            @click="update(company.uuid)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDeleteDialog"
      persistent
      width="600"
    >
      <v-card>
        <v-card-title class="headline">
          Delete Confirm
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this data?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            large
            @click="confirmDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            large
            @click="destroy(company.uuid)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <v-skeleton-loader
    v-else
    v-bind="attrs"
    type="table"
  >
  </v-skeleton-loader>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'
// eslint-disable-next-line object-curly-newline
import { mdiMagnify, mdiDeleteOutline, mdiPencilOutline, mdiPlus, mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'Company',
  components: {
    DataTablePagination,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      isLoadingTable: true,
      search: '',
      addFormDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      addMessage: false,
      deleteMessage: false,
      updateMessage: false,
      page: 1,
      totalPages: 0,
      totalItems: 0,
      itemsPerPage: 10,
      companies: [],
      company: {
        uuid: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        logo: '',
      },
      headers: [
        { text: '#', value: 'index' },
        { text: 'Company Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Address', value: 'address' },
        { text: 'Action', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiImageMultipleOutline,
      },
      isLoadingButton: false,
    }
  },
  mounted() {
    this.list()
  },
  methods: {
    async list(params = {}) {
      this.isLoadingTable = true
      await this.$services.ApiServices.list(this.$options.name, { ...params, page: this.page }).then(
        ({ data }) => {
          this.companies = data.data.map((companies, index) => ({
            ...companies,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
    },
    resetForm() {
      this.company.name = ''
      this.company.email = ''
      this.company.phone = ''
      this.company.address = ''
      this.company.logo = ''
    },
    showFormAdd() {
      this.addFormDialog = true
      this.resetForm()
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.$options.name, this.company).then(
        ({ data }) => {
          this.list()
          this.addFormDialog = false
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
    },
    async edit(uuid) {
      await this.$services.ApiServices.get(this.$options.name, uuid).then(
        ({ data }) => {
          this.company = data.data
          this.editFormDialog = true
        },
        err => console.error(err),
      )
    },
    async update(uuid) {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.$options.name, this.company, uuid).then(
        ({ data }) => {
          this.list()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.editFormDialog = false
    },
    confirmDestroy(uuid) {
      this.confirmDeleteDialog = true
      this.company.uuid = uuid
    },
    async destroy(uuid) {
      await this.$services.ApiServices.destroy(this.$options.name, uuid).then(
        ({ data }) => {
          this.list()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.confirmDeleteDialog = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
